import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import { NotFoundPage, Container, Fonts } from '../global.css';
import axios from 'axios';
import NProgress from 'nprogress';
import { trialDetailsUrl } from '../constants/config';
import TrialDetails from '../templates/studyDetailsPage';

class NotFound extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      canRender: false,
      canRenderStudyDetails: false,
      studyDetails: {},
    };
  }
  getStudyId = () => {
    if (typeof window !== 'undefined' && window.location) {
      const { pathname, href } = window.location;
      const matchOldStudyRoute = href.match(/\/study\/\?id=(\w+)/);
      const matchNewStudyRoute = pathname.match(/\/study\/(\w+)/);

      if (matchOldStudyRoute) {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('id');
      }
      if (matchNewStudyRoute) {
        const pathParams = window.location.pathname.split('/');
        return decodeURIComponent(pathParams[2]);
      }
    }
    return null;
  };

  componentDidMount() {
    const studyId = this.getStudyId();

    if (studyId) {
      // Check if the id is a unique Id
      axios
        .get(trialDetailsUrl(studyId))
        .then(res => {
          this.setState({
            canRenderStudyDetails: true,
            canRender: true,
            studyDetails: res.data,
          });
        })
        // If it is not unique, check if it is old Id
        .catch(() => {
          axios
            .get(trialDetailsUrl(studyId, 'old'))
            .then(res => {
              this.setState({
                canRenderStudyDetails: true,
                canRender: true,
                studyDetails: res.data,
              });
            })
            .catch(() => {
              NProgress.done();
              this.setState({ canRender: true });
            });
        });

      return;
    }

    this.setState({ canRender: true });
  }

  render() {
    const { data } = this.props;
    return this.state.canRender ? (
      this.state.studyDetails && this.state.canRenderStudyDetails ? (
        <TrialDetails
          location={location}
          data={{
            studyDetails: this.state.studyDetails.protocol_form,
            site: data.site,
            studyNotFoundJson: data.studyNotFoundJson,
            trialDetailsEnJson: data.trialDetailsEnJson,
            isRedirected: true,
          }}
          pageContext={{
            studyId: this.state.studyDetails.protocol_form.org_study_id,
          }}
        />
      ) : (
        <Fonts>
          <Layout>
            <NotFoundPage>
              <Container>
                <h1 className={'main-title'}>{data.notFoundJson.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.notFoundJson.content.childMarkdownRemark.html,
                  }}
                />
              </Container>
            </NotFoundPage>
          </Layout>
        </Fonts>
      )
    ) : null;
  }
}

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
  data: PropTypes.object,
};

export default NotFound;

export const query = graphql`
  query NotFoundQuery {
    notFoundJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    studyNotFoundJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    trialDetailsEnJson {
      ...TrialDetailsEnJsonFragment
    }
  }
`;
